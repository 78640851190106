<template>
  <div class="chatFoot">
    <div class="btm">
      <div class="left">
        <div class="box"
             @click="goBack">
          <img src="@/assets/bracelet_img/5.png"
               alt="">
        </div>
      </div>
      <div class="right">
        <van-badge :content="content > 0 ? content : '' " max="99" >
          <div @click="goTo('/inbox')"
               :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               class="ask pointer">{{$t('收件箱')}}</div>
        </van-badge>
        <div class="invite pointer"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="goTo('/friend')">{{$t('聊天')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      // type: String,
      default: function () {
        return '0'
      }
    }
  },
  components: {

  },
  data () {
    return {
      message: ''

    }
  },
  created () {

  },
  methods: {
    goTo (url) {
      this.$router.push({
        path: url
      })
    },
    /* 返回上一页 */
    goBack () {
      this.$router.go(-1);
    },

  }
}
</script>

<style lang="less" scoped>
.chatFoot {
  .btm {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 22px;
    .left {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 0 5px 5px 0;
      padding: 5px 5px 5px 0;
      margin-right: 2px;
      .box {
        padding: 4px 10px 4px 8px;
        background-color: rgba(43, 43, 43, 0.9);
        border-radius: 0 5px 5px 0;
        border: 2px solid #7736fe;
        border-left: none;
        display: flex;
        img {
          width: 25px;
          height: 19px;
        }
      }
    }
    .right {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 5px 25px 5px 5px;
      padding: 5px 20px 5px 7px;
      display: flex;
      align-items: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18px;
      color: #2b2b2b;
      .ask {
        background-image: linear-gradient(#a7f264, #a7f264),
          linear-gradient(#5c65cb, #5c65cb);
        background-blend-mode: normal, normal;

        box-shadow: 2px 2px 1px 0px rgba(19, 19, 19, 0.16);
        border-radius: 20px 5px 20px 5px;
        width: 130px;
        text-align: center;
        margin-right: 6px;
        padding: 5px 0;
      }
      .invite {
        background-color: #5c65cb;
        box-shadow: 2px 2px 1px 0px rgba(19, 19, 19, 0.16);
        border-radius: 20px 5px 20px 5px;
        width: 130px;
        text-align: center;
        padding: 5px 0;
      }
      /deep/.van-badge--fixed {
        top: 10px;
        right: 18px;
      }
    }
  }
}
</style>
<template>
  <div class="message">
    <watch-head :title="$t('消息/聊天')"
                :img="require('@/assets/bracelet_img/67.png')" />
    <div class="main">
      <div class="name">{{ friendInfo.nickname || $t('暂无昵称') }}</div>
      <!-- <div class="input">输入当中...</div> -->
      <div class="input"></div>
      <van-pull-refresh class="msg"
                        ref="pullRefs"
                        v-model="refreshing"
                        @refresh="getMessageList">
        <div class="row"
             v-for="(item, index) in list"
             :key="index">
          <div v-if="item.from == Historical.id" class="left">
            <div class="avatar">
              <img class="img" :src="friendInfo.avatar" alt="">
              <div class="grade">{{ friendInfo.level_grade }}</div>
            </div>
            <div class="text">{{item.payload.text}}</div>
          </div>
          <div v-else class="right">
            <div class="text">{{item.payload.text}}</div>
            <div class="avatar">
              <img class="img" :src="userInfo.avatar" alt="">
              <div class="grade">{{ userInfo.level_grade }}</div>
            </div>
          </div>
        </div>

      </van-pull-refresh>
      <div class="send">
        <van-field class="sendOut"
                   v-model="message"
                   label-width="0"
                   :placeholder="$t('在此输入...')">
          <template #button>
            <van-button :loading="isMsgLoading" @click="sendOut()" :icon="require('@/assets/bracelet_img/68.png')" />
          </template>
        </van-field>
      </div>
    </div>

    <chat-foot />
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import chatFoot from '@/views/chat/components/chatFoot.vue'
import { getInfoBy } from '@/api/api'
import { getMessageList, timIsLogin, createMessage, singleChat, setMessageRead } from '@/utils/tim.js'
export default {
  components: {
    watchHead,
    chatFoot
  },
  data () {
    return {
      timer: null,
      message: '',
      refreshing: false,
      list: [],
      userInfo: {},
      Historical: {
        page: 1,
        limit: 20,
        id: '',
      },
      friendInfo: { 
        nickname: ''
      },
      WebSocketLoad: null,
      isMsgLoading: false,
      nextReqMessageID: null,
      isCompleted: false,
    }
  },
  created () {
    this.waitForGlobal()
  },
  mounted(){
    // 监听消息
    singleChat((data) => {
      if(this.$route.path == '/message'){
        this.list.push(data)
        let timer = setTimeout(() => {
          clearTimeout(timer)
          this.$refs.pullRefs.$el.scrollTop = 999999
        }, 100)
        this.reportedRead(`C2C${this.Historical.id}`);
      }
    })
  },
  methods: {
    // 获取好友相关信息
    getFriendInfo(uid){
      getInfoBy({uid}).then(res => {
        if(res.code == 0){
          this.friendInfo = res.data
        }
      })
    },
    // 将会话下所有未读消息已读上报
    reportedRead(conversationID){
      setMessageRead(conversationID)
    },
    //im获取历史消息
    getMessageList(){
      if (this.isCompleted) {
        this.$toast(this.$t('没有更多了'))
        this.refreshing = false
        return
      }
      let params = {
        conversationID: `C2C${this.Historical.id}`,
        nextReqMessageID: this.nextReqMessageID
      }
      getMessageList(params).then(res => {
        if (res.code == 0) {
          // this.messageList = res.data.messageList; // 消息列表。
          this.nextReqMessageID = res.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
          this.isCompleted = res.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
          console.log('历史聊天记录-----',res.data.messageList,this.nextReqMessageID,this.isCompleted);
          this.list = [].concat(res.data.messageList, this.list)
          if (this.Historical.page == 1) {
            let timer = setTimeout(() => {
              clearTimeout(timer)
              this.$refs.pullRefs.$el.scrollTop = 999999
            }, 100)
          } else {
            let timer = setTimeout(() => {
              clearTimeout(timer)
              this.$refs.pullRefs.$el.scrollTop = (-(this.$refs.pullRefs.$el.scrollHeight - (this.$refs.pullRefs.$el.scrollHeight + this.$refs.pullRefs.$el.scrollTop + this.$refs.pullRefs.$el.clientHeight))) + this.$refs.pullRefs.$el.clientHeight
            }, 100)
          }
          this.Historical.page++
          this.refreshing = false
        }
      });
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo') && timIsLogin) {
        window.clearTimeout(this.timer);
        this.Historical.id = this.$route.query.fromUid
        this.getUserInfo();
        this.reportedRead(`C2C${this.Historical.id}`);
        this.getFriendInfo(this.Historical.id);
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    /* 获取当前用户信息 */
    getUserInfo () {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

      this.Historical.page = 1
      this.isCompleted = false
      this.nextReqMessageID = null
      this.list = []
      this.getMessageList()
    },

    /* 发送消息 */
    sendOut () {
      if (this.message.length == 0) {
        this.$toast(this.$t('发送消息不能为空'))
        return
      }
      if(!this.Historical.id){
        this.$toast(this.$t('请先选择朋友'))
        return
      }
      this.isMsgLoading = true
      let params = {
        type: 'C2C',
        id: this.Historical.id,
        text: this.message
      }
      createMessage(params).then((res) => {
        // 发送成功
        if (res.code == 0) {
          this.list.push(res.data.message)
          let timer = setTimeout(() => {
            clearTimeout(timer)
            this.$refs.pullRefs.$el.scrollTop = 999999
          }, 100)
          if (res.data.message.from == this.userInfo.id) {
            this.message = ''
          }
        }
        this.isMsgLoading = false
      }).catch((imError) => {
        this.isMsgLoading = false
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
    }
  }
}
</script>

<style lang="less" scoped>
.message {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 80px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .main {
    margin-top: 30px;
    padding: 0 33px;
    .name {
      font-family: 'AeroMaticsBold';
      font-size: 29px;
    }
    .input {
      font-family: 'TsangerYuYangT-W05';
      font-size: 12px;
      margin: -2px 0 4px;
    }
    .msg {
      width: 100%;
      height: 457px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      padding: 12px;
      overflow-y: scroll;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .row {
        margin-bottom: 10px;
        .left {
          display: flex;
          align-items: center;
          .avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: #2b2b2b;
            border: 3px solid #2b2b2b;
            border-radius: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .grade {
              position: absolute;
              right: -10px;
              bottom: -2px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #2b2b2b;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .text {
            background-color: #ffffff;
            border-radius: 15px;
            padding: 8px 14px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            margin-left: 8px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: #2b2b2b;
            border: 3px solid #2b2b2b;
            border-radius: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .grade {
              position: absolute;
              right: -10px;
              bottom: -2px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #2b2b2b;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .text {
            background-color: #ffffff;
            border-radius: 15px;
            padding: 8px 14px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            margin-right: 8px;
          }
        }
      }
    }
    .msg::-webkit-scrollbar {
      display: none;
    }
    .send {
      margin-top: 2px;
      .van-field {
        background-color: #ffffff;
        border-radius: 8px;
        // padding: 2px 7px;
        /deep/.van-field__right-icon {
          display: flex;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
        /deep/.xia {
          width: 20px;
          height: 20px;
        }
      }
      .sendOut {
        /deep/.van-field__control {
          font-family: 'TsangerYuYangT-W03';
          font-size: 15px;
          color: #2b2b2b;
        }
        /* WebKit, Blink, Edge */
        /deep/.van-field__control::-webkit-input-placeholder {
          font-size: 15px;
        }
        /* Mozilla Firefox 4 to 18 */
        /deep/.van-field__control:-moz-placeholder {
          font-size: 15px;
        }
        /* Mozilla Firefox 19+ */
        /deep/.van-field__control::-moz-placeholder {
          font-size: 15px;
        }
        /* Internet Explorer 10-11 */
        /deep/.van-field__control:-ms-input-placeholder {
          font-size: 15px;
        }
        /* Microsoft Edge */
        /deep/.van-field__control::-ms-input-placeholder {
          font-size: 15px;
        }
        /deep/.van-field__button{
          line-height: initial;
          display: flex;
        }
        .van-button{
          border: none;
          padding: 0;
          height: auto;
          .van-icon__image{
            width: 20px;
            height: 20px;
          }
        }
        .van-button::before{
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>